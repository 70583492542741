#all-filter {
  margin-bottom: 30px;
  li {
    @include badge(#aaa);
    display: inline-block;
    &.active {
      @include badge(lightgreen)
    }
  }
}

.ribbon {
  @include transform(rotate(45deg));
  background-color: #578FE0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 0.8em;
  right: -25px;
  line-height: 1.5em;
  padding: 2px 7px;
  position: absolute;
  text-align: center;
  top: 20px;
  width: 120px;
}




.expander {
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $base-font-color: $dark-gray !default;
  $expander-arrow-width: 0.7em;
  $expander-toggle-size: 1em;
  $expander-toggle-arrow-size: $expander-toggle-size;
  $expander-toggle-margin: 1em;

  div.awesomplete {
    display: block;
  }

  .expander-trigger {
    @include user-select(none);
    border-bottom: 1px solid $light-gray;
    color: $action-color;
    cursor: pointer;
    display: block;
    font-size: $expander-toggle-size;
    margin-bottom: $expander-toggle-size;
    padding-bottom: $expander-toggle-size / 4;
    text-decoration: none;

    &:before {
      font-size: $expander-arrow-width;
      content: "\25BC";
      margin-right: 0.5em;
    }
  }
  .expander-content {
    margin-left: 30px;
  }

  .expander-content p {
    color: $base-font-color;
    line-height: $base-line-height;
  }

  .expander-hidden {
    &:before {
      font-size: $expander-arrow-width;
      content: "\25BA";
    }
  }

  .expander-hidden + .expander-content {
    display: none;
  }
}


#projects {
  @include display(flex);
  @include flex-wrap(wrap);
  @include justify-content(space-between);


  .card {
    $base-border-color: gainsboro !default;
    $base-border-radius: 3px !default;
    $base-background-color: white !default;
    $base-spacing: 1.5em !default;
    $action-color: #477DCA !default;
    $dark-gray: #333 !default;
    $base-font-color: $dark-gray !default;
    $card-border-color: $base-border-color;
    $card-border: 1px solid $card-border-color;
    $card-background: lighten($card-border-color, 10%);
    $card-header-background: $card-background;
    $card-margin: 1em;
    $card-image-hover-opacity: 0.7;
    $card-image-hover-color: #F8F2B4;

    @include flex-basis(15em);
    @include flex-grow(1);
    @include transition (all 0.2s ease-in-out);
    background-color: $card-background;
    border-radius: $base-border-radius;
    border: $card-border;
    box-shadow: 0 2px 4px darken($base-background-color, 10%);
    cursor: pointer;
    margin: 0 $card-margin $base-spacing $card-margin;
    position: relative;
    overflow: hidden;

    &.hidden {
      display: none;
    }

    .card-image {
      overflow: hidden;
      height: 30vh;
      max-height: 30vh;
      background-size: cover;
      background-position: center;
      @include supergradient;
    }

    .card-header {
      @include transition (all 0.2s ease-in-out);
      background-color: $card-header-background;
      border-bottom: $card-border;
      border-radius: $base-border-radius $base-border-radius 0 0;

      padding: ($base-spacing / 3) ($base-spacing / 2);
      h2 {
        font-weight: bold;
        line-height: 1.5em;
        .more {
          font-size: 0.85em;
          font-weight: 300;
        }
      }
      h3.tasks {
        font-size: 0.8em;
        margin-top: -10px;
      }
      .links {
        float: right;
        margin-top: -15px;
        svg {
          height: 1.5em;
          width: 1.5em;
        }
      }
    }

    .card-copy {
      font-size: 0.9em;
      line-height: 1.5em;
      padding: ($base-spacing / 2) ($base-spacing / 2);

      p {
        margin: 0 0 ($base-spacing / 2);
      }
    }

    .task-badge {
      @include badge(#68C968)
    }

    .tech-badge {
      @include badge(#C566E9)
    }

    &:focus,
    &:hover {
      cursor: pointer;

      img {
        opacity: $card-image-hover-opacity;
      }
    }

    &:active {
      background-color: $card-background;

      .card-header {
        background-color: $card-background;
      }
    }

    &:not(.active-box){
      .more {
        display: none
      }
    }

    &.active-box {
      flex-basis: 50%;
      .hide-on-more {
        display: none;
      }

      .card-image {
        overflow: hidden;
        height: 60vh;
        max-height: 60vh;
      }
    }
  }
}
