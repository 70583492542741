/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

.no-print {
  @media print {
    display: none;
  }
}


.print-only {
  @media screen {
    display: none;
  }
}

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 200;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;

    @media print {
      font-family: serif;
      font-size: 10px;
    }
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }


    @media print {
      max-width: 100%;
      width: 100%;
    }
}


.rainbow {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjglIiBzdHlsZT0ic3RvcC1jb2xvcjojNjEyMEJEOyIgLz48c3RvcCBvZmZzZXQ9IjIxJSIgc3R5bGU9InN0b3AtY29sb3I6I0JGMjAyMDsiIC8+PHN0b3Agb2Zmc2V0PSIzMCUiIHN0eWxlPSJzdG9wLWNvbG9yOiNGMjk1MkM7IiAvPjxzdG9wIG9mZnNldD0iMzclIiBzdHlsZT0ic3RvcC1jb2xvcjojRTNDRDUyOyIgLz48c3RvcCBvZmZzZXQ9IjQzJSIgc3R5bGU9InN0b3AtY29sb3I6Izg1QkY0MzsiIC8+PHN0b3Agb2Zmc2V0PSI1MyUiIHN0eWxlPSJzdG9wLWNvbG9yOiM0RkJEMjA7IiAvPjxzdG9wIG9mZnNldD0iNjMlIiBzdHlsZT0ic3RvcC1jb2xvcjojMjBCRDIyOyIgLz48c3RvcCBvZmZzZXQ9Ijc1JSIgc3R5bGU9InN0b3AtY29sb3I6IzEwQ0ZBNjsiIC8+PHN0b3Agb2Zmc2V0PSI4NyUiIHN0eWxlPSJzdG9wLWNvbG9yOiMwMDk1RkY7IiAvPjxzdG9wIG9mZnNldD0iOTklIiBzdHlsZT0ic3RvcC1jb2xvcjojNTIyQkZGOyIgLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCBmaWxsPSJ1cmwoI2dyYWRpZW50KSIgaGVpZ2h0PSIxMDAlIiB3aWR0aD0iMTAwJSIgLz48L3N2Zz4=);
  background-image: -webkit-gradient(linear, left top, 90deg, color-stop(8%, #6120BD), color-stop(21%, #BF2020), color-stop(30%, #F2952C), color-stop(37%, #E3CD52), color-stop(43%, #85BF43), color-stop(53%, #4FBD20), color-stop(63%, #20BD22), color-stop(75%, #10CFA6), color-stop(87%, #0095FF), color-stop(99%, #522BFF));
  background-image: -moz-linear-gradient(90deg,#6120BD 8%,#BF2020 21%,#F2952C 30%,#E3CD52 37%,#85BF43 43%,#4FBD20 53%,#20BD22 63%,#10CFA6 75%,#0095FF 87%,#522BFF 99%);
  background-image: -webkit-linear-gradient(90deg,#6120BD 8%,#BF2020 21%,#F2952C 30%,#E3CD52 37%,#85BF43 43%,#4FBD20 53%,#20BD22 63%,#10CFA6 75%,#0095FF 87%,#522BFF 99%);
  background-image: -o-linear-gradient(90deg,#6120BD 8%,#BF2020 21%,#F2952C 30%,#E3CD52 37%,#85BF43 43%,#4FBD20 53%,#20BD22 63%,#10CFA6 75%,#0095FF 87%,#522BFF 99%);
  background-image: -ms-linear-gradient(90deg,#6120BD 8%,#BF2020 21%,#F2952C 30%,#E3CD52 37%,#85BF43 43%,#4FBD20 53%,#20BD22 63%,#10CFA6 75%,#0095FF 87%,#522BFF 99%);
  background-image: linear-gradient(90deg,#6120BD 8%,#BF2020 21%,#F2952C 30%,#E3CD52 37%,#85BF43 43%,#4FBD20 53%,#20BD22 63%,#10CFA6 75%,#0095FF 87%,#522BFF 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position:relative;
  display:inline-block; /*required*/
}

.site-title {
  font-weight: bold;
  opacity: 0.75;
  transition: opacity ease-in-out 0.5s;
}

a.site-title:hover {
  text-decoration: none;
  opacity: 1;
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

img.avatar {
  height: 130px;
  border-radius: 50%;
  border: 2px solid #333;
  float: left;
  margin: 10px;
}

img.socialicon {
  height: 1.25em;
  display: inline-block;
  vertical-align: middle;
}

/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

@mixin supergradient {
    background-image: linear-gradient(-55deg, #EA8711, #D96363, #73A6DF, #9085FB, #52CA79);
}


@mixin badge($color: #999) {
  background-color: $color;
  border-radius: $badge-font-size * 5;
  color: darken($color, 60%);
  display: inline-block;
  font-size: $badge-font-size;
  line-height: 1;
  padding: 0.4em 1.2em;
}
