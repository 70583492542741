
.post-header {
  $base-border-radius: 3px !default;
  $action-color: #477DCA !default;
  $large-screen: em(860) !default;
  $hero-color: white;
  $gradient-angle: 10deg;
  // $hero-image: 'https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/mountains.png';

  // @include background(url($hero-image), linear-gradient($gradient-angle, $hero-background-bottom, $hero-background-top), no-repeat $hero-background-top scroll);
  @include supergradient;
  // background-color: #324766;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 3em;
  width: 99vw;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 35vw;

  h1 {
    color: $hero-color;
    font-weight: 200;
    font-size: 1.5em;
    font-weight: 300;
    margin: 0.5em 0.25em;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(99, 99, 99, 0.5);
    display: block;
    padding: 10px;

    @include media($large-screen) {
      font-size: 2.5em;
    }
  }
}

.post {
  .post-content {
    font-size: 18px;
    font-weight: 200;
    text-align: justify;
    > blockquote {
      font-style: unset;
    }
    video, iframe {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

.post-list {
  li img {
    width: 80%;
  }
}

.post-list-short {
  > li {
    margin-bottom: 2em;
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.05em
    }
  }
  
}