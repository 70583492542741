
.resume-head {
  @media print {
    display: flex;
    .avatar {
      max-width: 150px;
      max-height: 150px;
    }
    h4 {
        font-size: 0.85em;
    }
    .socialicon {
      width: 1em;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 2em;
        white-space: nowrap;
      }
    }
  }
}

.resume {
    letter-spacing: 1px;
    h2 {
        text-transform: uppercase;
        line-height: 2em;
        font-size: 12px;
        margin-top: 30px;

        @media screen {
          font-size: 20px;
        }
    }
    h3 {
        text-transform: uppercase;
        margin: 40px 0 10px;
        font-size: 12px;
        @media screen {
          font-size: 18px;
        }

        .time {
          padding-right: 2em;
          @media screen {
            width: 20vw;
            padding: 0 2em;
          }
          display: inline-block;
          font-size: 0.9em;
        }
    }

    h4 {
        font-size: 0.85em;
        margin-bottom: 20px;
    }

    h4, p, ul {
        letter-spacing: 0.2px;

        @media screen {
          margin-left: 20vw;
        }
        p {
            margin-left: 0;
        }
        li:before{
          display: inline-block;
          content: '\b7\a0';
          padding: 0 0.2em;
        }
    }

    @media print {
      font-size: 12px;
    }

    @media screen and (max-width: 920px) {
        h3 .time {
            display: block;
            width: 100%;
            padding: 5px 0;
        }
        h4 {
            margin-left: 0;
        }
        h2, p, ul, {
            margin-left: 5px;
        }
    }
}
